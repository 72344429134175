import Custom from './horizontal';

window.addEventListener('load', function () {

    let menu = document.querySelector('.main-nav');
    let homeHero = document.querySelector('.home-hero');
    let burger = document.querySelector('.burger');
    let whoLink = document.querySelector('.who-link');
    let whatLink = document.querySelector('.what-link');

    if (document.querySelector('.js-horizontal-scroll')) {

        const horizontal = new Custom({
            native: true,
            direction: 'vertical',
            section: document.querySelector('.js-horizontal-scroll')
        })

        horizontal.init();
            
        if (document.querySelector('#who')) {
            let whoSection = document.querySelector('#who').getBoundingClientRect().left;
            let whatSection = document.querySelector('#what').getBoundingClientRect().left;
        
            whoLink.onclick = function (e) {
                e.preventDefault(); 
                horizontal.scrollTo(whoSection, 0);
                menu.classList.add('-closed');
                homeHero.classList.add('-scrolled');
            };

            whatLink.onclick = function (e) {
                e.preventDefault(); 
                horizontal.scrollTo(whatSection - 60, 0);
                menu.classList.add('-closed');
                homeHero.classList.add('-scrolled');
            };

        }

    }

    burger.onclick = function () {
        menu.classList.remove('-closed');
    }

    if (homeHero) {
        homeHero.classList.remove('-scrolled');
        menu.classList.remove('-closed');
    }

    document.addEventListener('wheel', function (e) {
        let lastScrollTop = 0;
        let st = window.pageYOffset || document.documentElement.scrollTop;
        if (st > lastScrollTop) {
            menu.classList.add('-closed');    
            if (homeHero) {
                homeHero.classList.add('-scrolled');
            }
        }
        lastScrollTop = st <= 0 ? 0 : st;
    });
});

