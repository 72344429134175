window.addEventListener('load', function () {
    let button = document.querySelectorAll('.js-service-btn');
    let modal = document.querySelectorAll('.service-modal');
    modal.forEach(function (m) {
        let close = m.querySelectorAll('.js-service-close');
        let overlay = m.querySelectorAll('.overlay');
        let modalInner = m.querySelectorAll('.inner');
        let modalContent = m.querySelectorAll('.content');
        let tl = new TimelineMax({paused: false});

        tl 
            .fromTo(m, 0.1, {css: {'display':'none'}}, {css: {'display':'flex'}})
            .fromTo(overlay, 0.4, {autoAlpha: 0}, {autoAlpha: 1}, 0)
            .fromTo(modalInner, 0.4, {transform: 'rotateY(90deg)'}, {transform: 'rotateY(0deg)'}, 0.2)
            .fromTo(close, 0.4, {autoAlpha: 0, transform: 'translateY(20px)'}, {autoAlpha: 1, transform: 'translateY(0)'}, 0.4)
            .fromTo(modalContent, 0.4, {autoAlpha: 0, transform: 'translateY(20px)'}, {autoAlpha: 1, transform: 'translateY(0)'}, 0.4)

        tl.reverse();

        let dataModal = m.dataset.index;
        button.forEach(function (btn) {
            btn.addEventListener('click', function (e) {
                e.preventDefault();
                let dataBtn = this.dataset.index;
                if (dataBtn === dataModal) {
                    tl.reversed(!tl.reversed());
                }
                console.log(dataBtn);
            })
        });

        close.forEach(function (c) {
            c.addEventListener('click', function () {
                tl.reversed(!tl.reversed());
            });
        });
    }); 
});