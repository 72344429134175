import Smooth from 'smooth-scrolling'

class Parallax extends Smooth {

  constructor(opt) {
    super(opt)
    this.resizing = false
  }

  resize() {
    this.resizing = true
    super.resize()
    this.vars.bounding = this.dom.section.getBoundingClientRect().width - this.vars.height
    this.dom.scroll.style.width = ``
    this.dom.scroll.style.height = `${this.vars.bounding}px`
    this.resizing = false
  }

  run() {
    this.dom.section.style[this.prefix] = `translate3d(${this.vars.current * -1}px,0,0)`
    super.run()
  }
}

export default Parallax