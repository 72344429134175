window.addEventListener('load', function () {
    let button = document.querySelector('.js-contacts-btn');
    let modal = document.querySelector('.feedback');
    let close = document.querySelector('.js-feedback-close');
    let overlay = document.querySelectorAll('.feedback .overlay');
    let modalInner = document.querySelectorAll('.feedback .inner');
    let modalContent = document.querySelectorAll('.feedback .content');
    let tl = new TimelineMax({paused: false});

    tl 
        .fromTo(modal, 0.1, {css: {'display':'none'}}, {css: {'display':'flex'}})
        .fromTo(overlay, 0.4, {autoAlpha: 0}, {autoAlpha: 1}, 0)
        .fromTo(modalInner, 0.4, {transform: 'rotateY(90deg)'}, {transform: 'rotateY(0deg)'}, 0.2)
        .fromTo(modalContent, 0.4, {autoAlpha: 0, transform: 'translateY(20px)'}, {autoAlpha: 1, transform: 'translateY(0)'}, 0.4)

    tl.reverse();

    if (button) {
        button.addEventListener('click', function () {
            tl.reversed(!tl.reversed());
        });

        close.addEventListener('click', function () {
            tl.reversed(!tl.reversed());
        });
    }
        
});