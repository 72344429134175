window.addEventListener('load', function () {
    if (document.querySelector('.mobile-nav')) {
        let burger = document.querySelector('.mobile-nav .burger');
        let menu = document.querySelectorAll('.mobile-nav');
        let link = document.querySelectorAll('.mobile-nav .list a');
        let tl = new TimelineMax({paused: false});


        tl 
        .fromTo(menu, 0.6, {css:{'height':'62px'}}, {css:{'height':'100vh'}});

        tl.reverse();

        burger.addEventListener('click', function () {
            tl.reversed(!tl.reversed());    
            this.classList.toggle('-open');
        });
        link.forEach(function (item) {
            item.addEventListener('click', function () {
                tl.reversed(!tl.reversed());    
                burger.classList.remove('-open');
            });
        });
    }
});