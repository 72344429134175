import Smooth from 'smooth-scrolling'

window.addEventListener('load', function () {
    if (document.querySelector('.projects-scroller')) {
        const projectsScroller = new Smooth({
            native: true,
            direction: 'vertical',
            section: document.querySelector('.projects-scroller')
        })

        projectsScroller.init();
    }
});