// Main javascript entry point
// Should handle bootstrapping/starting application

// 'use strict';

import './partials/mobile';
import './partials/home';
import './partials/contacts';
import './partials/projects';
import './partials/service-modal';
import './partials/team-modal';